$colorPrincipal: #00B4BB;
$colorTitulos: #14B4BB;
$colorButtonOscuro: #0F8489;
$colorButtonClaro: #78CCD0;
$colorTextoClaro: #14B4BB;
$colorNav: #14B4BB;

$colorNuevo: #0F8489;
$colorRevision: #E83946;
$colorValidar: #7AC453;
$colorCompleto: #171717;
$colorCancelado: #6E6E6E;
$colorPausado: yellow;

$colorBackground: #F3F4F4;
$colorBackground2: #14B4BB;
$colorLoading: #14B4BB;

.f-status{
    font-size: 14px;
}

.event--content{
    font-size: 61px;
    height: calc(100%);
    width: 100%;
    color: $colorNuevo;
}

.text-primary{
    color: $colorNuevo !important;
    font-weight: bold;
}

.text-danger{
    color: $colorRevision !important;
    font-weight: bold;
}

.text-success{
    color: $colorValidar !important;
    font-weight: bold;
}

.text-dark{
    color: $colorCompleto !important;
    font-weight: bold;
}

.text-secondary{
    color: $colorCancelado !important;
    font-weight: bold;
}

.text-warning{
    color: $colorPausado !important;
    font-weight: bold;
}

.event--content .spinner-border{
    color: $colorLoading;
}


.background--color--principal{
    background-color: $colorPrincipal;
}

.content--login{
    height: calc(100%);
    position: absolute;
    width: 100%;
}

.title{
    color: $colorTitulos;
}

.btn--oscuro{
    background-color: $colorButtonOscuro;
    border-color: transparent;
}

.btn--claro{
    background-color: $colorButtonClaro;
    border-color: transparent;
}

.btn--comentado{
    background-color: $colorValidar;
    border-color: transparent;
}

.btn--sinComentar{
    background-color: $colorRevision;
    border-color: transparent;
}

.btn--conteo{
    margin: 5px;
    width: 45%;
    height: 40px;
    border-radius: .5em;
    font-size: 25px;
    text-align: center;
    padding-top: 0%;
}

.btn--login{
    font-size: 24px;
    font-weight: bold;
}

.btn--oscuro:focus{
    background-color: $colorButtonClaro;
    border-color: transparent;
}

.btn--oscuro:hover{
    background-color: $colorButtonOscuro;
    border-color: transparent;
}

.btn--claro:hover{
    background-color: $colorButtonClaro;
    border-color: transparent;
}

.btn--claro:focus{
    background-color: $colorButtonOscuro;
    border-color: transparent;
}

.btn--desktop-end{
    position: absolute;
    top: 100%;
    right: 0%;
    margin-top: 2%;
}
.btn--desktop-mid{
    position: absolute;
    top: 100%;
    right: 30%;
    margin-top: 2%;
}

.formTitulo{
    color: $colorTextoClaro;
}

.card--opciones--nav{
    /* modificar el valor del background por una constante */
    color: #6E6E6E;
    margin-bottom: 1em;
    cursor: pointer;
    border: transparent;
}

.loading--content{
    height: 100%;
}

.button--content--end{
    height: 65px;
    position: absolute;
    width: 100%;
    bottom: 0%;
}

.card--app{
    position: relative;
    height: 80%;
    background-color: #fff;
    border-radius: 8px;
    padding: 1em;
}

.user--content--img{
    min-height: 63px;
}

.user--content--img svg{
    font-size: 30px;
}

.content--table {
    height: calc(77%);
    position: absolute;
    width: 96%;
}
.content--error--desktop{
    height: calc(80%);
    margin-top: 10%;
    width: 35%;
    margin-left: 32%;
    text-justify: auto;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

    .checked--desktop{
        background-color: $colorButtonOscuro;
        color: #fff;
    }

    .event--content{
        border-radius: 8px;
        background-color: #fff;
    }

    .background--desktop{
        background: linear-gradient(to bottom, $colorBackground2, $colorBackground2 250px, $colorBackground 10%);
        position: absolute;
        height: calc(100%);
    }

    .content--close--logo--movil{
        display: none;
    }

    .background--movil--desktop{
        background-color: #fff;
        border-radius: 8px;
        padding: 1em;
        height: 80%;
    }

    .table--ipesa tbody tr td{
        vertical-align: middle;
    }

    .table--ipesa tbody tr td p {
        margin-bottom: 0px;
        font-size: 13px;
    }
    
}


/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

    .checked--desktop{
        background-color: $colorButtonOscuro;
        color: #fff;
    }

    .event--content{
        border-radius: 8px;
        background-color: #fff;
    }

    .background--desktop{
        background: linear-gradient(to bottom, $colorBackground2, $colorBackground2 250px, $colorBackground 10%);
        min-height: 630px;
    }

    .background--movil--desktop{
        background-color: #fff;
        border-radius: 8px;
        padding: 1em;
        height: 100%;
    }

    .content--close--logo--movil{
        display: none;
    }

}
  
/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
*/
  
@media (min-width: 768px) and (max-width: 1024px) {

    .navbar--movil .shadow {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,0)!important;
    }
    
    .content--movil{
        height: calc(100%);
        position: absolute;
        width: 100%;
    }
    
    .card--app{
        border: transparent;
        padding: 0px;
    }
    
    .logo--login{
        width: 159px;
        height: 60px;
    }
    
    .form-label{
        font-size: 15px;
    }
    
    .title{
        font-size: 24px;
        font-weight: bold;
    }
    
    .btn--login{
        font-size: 24px;
        font-weight: bold;
    }
    
    .btn--conteo{
        width: 47%;
        height: 4rem;
        margin: auto;
    }
    
    .navbar{
        background-color: $colorNav !important;
        height: 74px;
        padding: 0 1em;
    }
    
    .back--app{
        font-size: 31px;
        color: #fff;
        font-weight: bolder;
    }
    
    .navbar-expand .navbar-nav{
        width: 100%;
    }
    
    .navbar--content{
        width: 100%;
    }
    
    .text--navbar{
        font-size: 20px;
        font-weight: bolder;
        color: #fff !important;
    }
    
    .content--table{
        height: calc(77%);
        position: absolute;
        width: 100%;
    }
    .content--error{
        height: calc(80%);
        margin-top: 50%;
    }
    .conteo{
        max-height: 350px;
        min-height: 350px;
    }
    .productosLista{
        max-height: 250px;
        min-height: 250px;
    }
    
    div.numConteo {
        text-align: center;
        line-height: 100px;
    }
    .contador{
        font-size: 10rem;
        display: inline-block;
        vertical-align: middle;
        line-height: initial;
        color: $colorTextoClaro
    }
    
    .table--ipesa{
        border-collapse: inherit;
    }
    
    .table--ipesa thead{
        display: none;
    }
    
    .table--ipesa tbody tr{
        display: grid;
        border: solid 1px #E3E3E3;
        border-radius: 10px;
        position: relative;
        background-color: #fff;
        margin-bottom: 0.5em;
        max-height: 76px;
    }
    
    .table--ipesa tbody tr td{
        border: transparent;
        padding-bottom: 0px;
    }
    
    .table--ipesa tbody tr td:first-child p{
        margin-bottom: 0em;
    }
    
    .table--ipesa tbody tr td:nth-child(2){
        position: absolute;
        right: 0%;
    }
    
    .table--ipesa tbody tr td:nth-child(4),
    .table--ipesa tbody tr td:nth-child(5),
    .table--ipesa tbody tr td:nth-child(6),
    .table--ipesa tbody tr td:nth-child(7){
        display: none;
    }
    
    .irregularidad{
        height: 100px;
        max-height: 150px !important;
    }
    
    .table--ipesa tbody .irregularidad td:first-child p{
        margin-bottom: 0em;
    }
    .table--ipesa tbody .irregularidad td:nth-child(2){
        position: absolute;
        left: 0% !important;
        top: 40%;
    }
    
    .table--ipesa tbody .irregularidad td:nth-child(3){
        position: absolute;
        left: 0% !important;
        top: 65%;
    }
    
    .table--ipesa tbody .irregularidad td:nth-child(4){
        display:flex;
        position: absolute;
        left: 45% !important;
        top: 65%;
    }
    
    .table--ipesa tbody .irregularidad td:nth-child(5){
        display:flex;
        position: absolute;
        right: 0% !important;
        top: 0%;
    }
    
    .table--ipesa--detalles{
        border-collapse: inherit;
    }
    
    .table--ipesa--detalles thead{
        display: none;
    }
    
    .table--ipesa--detalles tbody tr{
        display: grid;
        border: solid 1px #E3E3E3;
        border-radius: 10px;
        position: relative;
        background-color: #fff;
        margin-bottom: 0.5em;
        min-height: 80px;
    }
    
    .table--ipesa--detalles tbody tr td{
        border: transparent;
        padding: 0.2em 0.4em;
    }
    
    .table--ipesa--detalles tbody tr td:nth-child(3){
        text-align: end;
    }
    
    .navbar--movil{
        z-index: 100;
        padding: 1em 2em 0 !important;
        background-color: $colorLoading;
        position: absolute;
        transition: width 0.25s, opacity 0.25s, transform 0.25s, -webkit-transform 0.25s;
        height: calc(100%);
        width: 100%;
    }
    
    .navbar--movil--open{
        transform: translate3d(0%, 0, 0);
    }
    
    .navbar--movil--close{
        transform: translate3d(-100%, 0, 0);
    }
    
    .card--opciones--nav{
        /* modificar el valor del background por una constante */
        background-color: #78CCD0;
        color: #fff;
        border-radius: 8px;
        border: transparent;
        margin-bottom: 1em;
    }
    
    .content--close--logo--movil{
        text-align: center;
        position: relative;
        margin-bottom: 1.5em;
    }
    
    .content--close--logo--movil img{
        width: 131px;
    }
    
    .content--close--logo--movil .close
    {
        position: absolute;
        left: -1%;
        font-size: 42px;
        color: #fff;
    }
    
    .background--movil--app{
        background-color: $colorBackground;
        height: 100%;
    }

}
  
/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
*/
  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
*/
  
@media (min-width: 481px) and (max-width: 767px) {

    .navbar--movil .shadow {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,0)!important;
    }

    .card--app{
        border: transparent;
        padding: 0px;
    }

    .logo--login{
        width: 159px;
        height: 60px;
    }

    .form-label{
        font-size: 18px;
    }

    .title{
        font-size: 24px;
        font-weight: bold;
    }

    .btn--login{
        font-size: 24px;
        font-weight: bold;
    }

    .navbar{
        background-color: $colorNav !important;
        height: 74px;
        padding: 0 1em;
    }

    .back--app{
        font-size: 31px;
        color: #fff;
        font-weight: bolder;
    }

    .navbar-expand .navbar-nav{
        width: 100%;
    }

    .navbar--content{
        width: 100%;
    }

    .text--navbar{
        font-size: 20px;
        font-weight: bolder;
        color: #fff !important;
    }

    .content--table{
        max-height: 640px;
        min-height: 640px;
    }

    .table--ipesa{
        border-collapse: inherit;
    }

    .table--ipesa thead{
        display: none;
    }

    .table--ipesa tbody tr{
        display: grid;
        border: solid 1px #E3E3E3;
        border-radius: 10px;
        position: relative;
        background-color: #fff;
        margin-bottom: 0.5em;
        max-height: 76px;
    }

    .table--ipesa tbody tr td{
        border: transparent;
        padding-bottom: 0px;
    }

    .table--ipesa tbody tr td:first-child p{
        margin-bottom: 0em;
    }

    .table--ipesa tbody tr td:nth-child(2){
        position: absolute;
        right: 0%;
    }

    .table--ipesa tbody tr td:nth-child(4),
    .table--ipesa tbody tr td:nth-child(5),
    .table--ipesa tbody tr td:nth-child(6),
    .table--ipesa tbody tr td:nth-child(7){
        display: none;
    }

    .table--ipesa--detalles{
        border-collapse: inherit;
    }

    .table--ipesa--detalles thead{
        display: none;
    }

    .table--ipesa--detalles tbody tr{
        display: grid;
        border: solid 1px #E3E3E3;
        border-radius: 10px;
        position: relative;
        background-color: #fff;
        margin-bottom: 0.5em;
        min-height: 80px;
    }

    .table--ipesa--detalles tbody tr td{
        border: transparent;
        padding: 0.2em 0.4em;
    }

    .table--ipesa--detalles tbody tr td:nth-child(3){
        text-align: end;
    }

    .navbar--movil{
        z-index: 100;
        padding: 1em 2em 0 !important;
        background-color: $colorLoading;
        position: absolute;
        transition: width 0.25s, opacity 0.25s, transform 0.25s, -webkit-transform 0.25s;
        height: calc(100%);
        width: 100%;
    }

    .navbar--movil--open{
        transform: translate3d(0%, 0, 0);
    }

    .navbar--movil--close{
        transform: translate3d(-100%, 0, 0);
    }

    .card--opciones--nav{
        /* modificar el valor del background por una constante */
        background-color: #78CCD0;
        color: #fff;
        border-radius: 8px;
        border: transparent;
        margin-bottom: 1em;
    }

    .content--close--logo--movil{
        text-align: center;
        position: relative;
        margin-bottom: 1.5em;
    }

    .content--close--logo--movil img{
        width: 131px;
    }

    .content--close--logo--movil .close
    {
        position: absolute;
        left: -5%;
        font-size: 42px;
        color: #fff;
    }

    .background--movil--app{
        background-color: $colorBackground;
    }

}
  
/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
*/
  
@media (min-width: 320px) and (max-width: 480px) {

    .navbar--movil .shadow {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,0)!important;
    }
    
    .content--movil{
        height: calc(100%);
        position: absolute;
    }

    .card--app{
        border: transparent;
        padding: 0px;
    }

    .logo--login{
        width: 159px;
        height: 60px;
    }

    .form-label{
        font-size: 15px;
    }

    .title{
        font-size: 24px;
        font-weight: bold;
    }

    .btn--login{
        font-size: 24px;
        font-weight: bold;
    }

    .btn--conteo{
        width: 47%;
        height: 4rem;
        margin: auto;
    }

    .navbar{
        background-color: $colorNav !important;
        height: 74px;
        padding: 0 1em;
    }

    .back--app{
        font-size: 31px;
        color: #fff;
        font-weight: bolder;
    }

    .navbar-expand .navbar-nav{
        width: 100%;
    }

    .navbar--content{
        width: 100%;
    }

    .text--navbar{
        font-size: 20px;
        font-weight: bolder;
        color: #fff !important;
    }

    .content--table{
        height: calc(77%);
        position: absolute;
        width: 100%;
    }
    .content--error{
        height: calc(80%);
        margin-top: 50%;
    }
    .conteo{
        max-height: 350px;
        min-height: 350px;
    }
    .productosLista{
        max-height: 250px;
        min-height: 250px;
    }

    div.numConteo {
        text-align: center;
        line-height: 100px;
    }
    .contador{
        font-size: 10rem;
        display: inline-block;
        vertical-align: middle;
        line-height: initial;
        color: $colorTextoClaro
    }

    .table--ipesa{
        border-collapse: inherit;
    }

    .table--ipesa thead{
        display: none;
    }

    .table--ipesa tbody tr{
        display: grid;
        border: solid 1px #E3E3E3;
        border-radius: 10px;
        position: relative;
        background-color: #fff;
        margin-bottom: 0.5em;
        max-height: 76px;
    }

    .table--ipesa tbody tr td{
        border: transparent;
        padding-bottom: 0px;
    }

    .table--ipesa tbody tr td:first-child p{
        margin-bottom: 0em;
    }

    .table--ipesa tbody tr td:nth-child(2){
        position: absolute;
        right: 0%;
    }

    .table--ipesa tbody tr td:nth-child(4),
    .table--ipesa tbody tr td:nth-child(5),
    .table--ipesa tbody tr td:nth-child(6),
    .table--ipesa tbody tr td:nth-child(7){
        display: none;
    }

    .irregularidad{
        height: 100px;
        max-height: 150px !important;
    }

    .table--ipesa tbody .irregularidad td:first-child p{
        margin-bottom: 0em;
    }
    .table--ipesa tbody .irregularidad td:nth-child(2){
        position: absolute;
        left: 0% !important;
        top: 40%;
    }

    .table--ipesa tbody .irregularidad td:nth-child(3){
        position: absolute;
        left: 0% !important;
        top: 65%;
    }

    .table--ipesa tbody .irregularidad td:nth-child(4){
        display:flex;
        position: absolute;
        left: 45% !important;
        top: 65%;
    }

    .table--ipesa tbody .irregularidad td:nth-child(5){
        display:flex;
        position: absolute;
        right: 0% !important;
        top: 0%;
    }

    .table--ipesa--detalles{
        border-collapse: inherit;
    }

    .table--ipesa--detalles thead{
        display: none;
    }

    .table--ipesa--detalles tbody tr{
        display: grid;
        border: solid 1px #E3E3E3;
        border-radius: 10px;
        position: relative;
        background-color: #fff;
        margin-bottom: 0.5em;
        min-height: 80px;
    }

    .table--ipesa--detalles tbody tr td{
        border: transparent;
        padding: 0.2em 0.4em;
    }

    .table--ipesa--detalles tbody tr td:nth-child(3){
        text-align: end;
    }

    .navbar--movil{
        z-index: 100;
        padding: 1em 2em 0 !important;
        background-color: $colorLoading;
        position: absolute;
        transition: width 0.25s, opacity 0.25s, transform 0.25s, -webkit-transform 0.25s;
        height: calc(100%);
        width: 100%;
    }

    .navbar--movil--open{
        transform: translate3d(0%, 0, 0);
    }

    .navbar--movil--close{
        transform: translate3d(-100%, 0, 0);
    }

    .card--opciones--nav{
        /* modificar el valor del background por una constante */
        background-color: #78CCD0;
        color: #fff;
        border-radius: 8px;
        border: transparent;
        margin-bottom: 1em;
    }

    .content--close--logo--movil{
        text-align: center;
        position: relative;
        margin-bottom: 1.5em;
    }

    .content--close--logo--movil img{
        width: 131px;
    }

    .content--close--logo--movil .close
    {
        position: absolute;
        left: -5%;
        font-size: 42px;
        color: #fff;
    }

    .background--movil--app{
        background-color: $colorBackground;
        height: 100%;
    }
}